// src/pages/ReportTV.jsx

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import SiriLogoSvg from '../../assets/LogoSiri.png';
import TreeImage from '../../assets/icons/ArvoresSalvas.png';
import Co2Image from '../../assets/icons/Co2avoid.png';
import OceanPlastic from '../../assets/icons/PlasticoNoOceano.png';
import Reciclagem from '../../assets/icons/Reciclagem.png';
import Carro from '../../assets/icons/Car.png';
import Aviao from '../../assets/icons/Plane.png';
import Chuveiro from '../../assets/icons/shower.png';
import ArCondicionado from '../../assets/icons/airConditionator.png';
import QrCodeImage from '../../assets/qr-code-siri.png';
import DashboardBackground from '../../assets/dashboard-background.jpg';
import ImpactPie from '../../components/ImpactPie';
import ImpactBar from '../../components/ImpactBar';
import ImpactCard from '../../components/ImpactCard';
import useReportData from '../../hooks/useReportData';
import { useParams } from 'react-router-dom';
import UserService from '../../services/UserService';
import { isAuthenticated } from '../../services/auth';
import { baseImg } from '../../App';

// Styled Components adaptados para TV
const TVContainer = styled.div`
  width: 1920px;
  height: 1080px;
  background: url(${DashboardBackground}) no-repeat center center;
  background-size: cover;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;

  @media (max-width: 1920px) {
    width: 100%;
    height: auto;
    padding: 20px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 4px solid #ccc;
`;

const HeaderMiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderText = styled.p`
  color: #ffffff;
  font-size: 3rem;
  text-align: center; /* Alinhamento centralizado */
  font-weight: bold;
  margin: 20px 0; /* Espaçamento vertical */

  @media (max-width: 480px) {
    font-size: 2rem;
  }

  @media (min-width: 1921px) {
    font-size: 4rem;
  }
`;

const HeaderImg = styled.img`
  width: 120px;
  height: 120px;

  @media (min-width: 768px) {
    width: 150px;
    height: 150px;
  }

  @media (min-width: 1921px) {
    width: 180px;
    height: 180px;
  }
`;

const CardsContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px 0;
`;

const GraphsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 20px;
  gap: 20px;
  flex-wrap: nowrap;

  @media (max-width: 1920px) {
    flex-direction: column;
    align-items: center;
  }
`;

const BarGraphContainer = styled.div`
  flex: 0.7;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    padding: 15px;
    margin: 10px 0;
  }

  @media (min-width: 1921px) {
    padding: 40px;
    margin: 20px;
  }
`;

const PieGraphContainer = styled.div`
  flex: 0.3;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    padding: 15px;
    margin: 10px 0;
  }

  @media (min-width: 1921px) {
    padding: 40px;
    margin: 20px;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-top: 4px solid #ccc;
`;

const FooterImpactCardsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  flex: 0.6;
`;

const FooterInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex: 0.4;
  flex-wrap: wrap;
  min-width: 300px;
  margin: 20px;
`;

const FooterSiriContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  margin: 20px;
`;

const FooterImpactCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
  text-align: center;

  @media (min-width: 768px) {
    width: 250px;
  }
`;

const FooterImpactImage = styled.img`
  width: 80px;
  height: 80px;

  @media (min-width: 768px) {
    width: 100px;
    height: 100px;
  }

  @media (min-width: 1921px) {
    width: 120px;
    height: 120px;
  }
`;

const FooterImpactTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: #666;
  margin-top: 10px;

  @media (min-width: 1921px) {
    font-size: 1.3rem;
  }
`;

const FooterImpactValue = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-top: 5px;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1921px) {
    font-size: 1.8rem;
  }
`;

const FooterLogo = styled.img`
  width: 120px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    width: 150px;
  }

  @media (min-width: 1921px) {
    width: 180px;
  }
`;

const FooterText = styled.p`
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: bold;
  margin: 0;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 1.8rem;
  }

  @media (min-width: 1921px) {
    font-size: 2.2rem;
  }
`;

const QrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const ImpactTitle = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  color: #666;
  text-align: center;
  margin-top: 10px;

  @media (min-width: 1921px) {
    font-size: 1.3rem;
  }
`;

// Componentes para a mensagem de ausência de dados
const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.6); /* Fundo semitransparente para destaque */
  border-radius: 12px;
  border: 3px dashed #ff0000; /* Borda destacada */
`;

const NoDataIcon = styled(AiOutlineExclamationCircle)`
  color: #ff0000;
  font-size: 4rem;
  margin-bottom: 20px;
`;

const NoDataText = styled.p`
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  max-width: 800px;

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }

  @media (min-width: 1921px) {
    font-size: 2.5rem;
  }
`;

const LoadingSpinner = styled.div`
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  margin-top: 40px;
`;

export default function ReportTV() {
  const { id } = useParams<{ id: string }>();
  const user_id = Number(id);
  const [userName, setUserName] = useState<string | null>(null);
  const [userImg, setUserImg] = useState<string | undefined>(undefined);
  const [mensagemTela, setMensagemTela] = useState("");
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [years, setYears] = useState<number[]>([]);

  const { data, setData } = useReportData(user_id, selectedYear);

  // Função para buscar informações do usuário
  const checkLoginStatus = async () => {
    try {
      if (isAuthenticated()) {
        const user = await UserService.getById(user_id);
        setUserName(user.name);
        setUserImg(user.img ? baseImg + user.img : '');
        setMensagemTela(`${user.name}: Resultados da Gestão de Resíduos Sólidos`);
        return true;
      } else {
        const user = await UserService.getBasicInfo(user_id);
        setUserName(user.name);
        setUserImg(user.img ? baseImg + user.img : '');
        setMensagemTela(`${user.name}: Resultados da Gestão de Resíduos Sólidos`);
        return false;
      }
    } catch (error) {
      console.error("Erro ao verificar status de login:", error);
    }
  };

  useEffect(() => {
    // Gerar a lista de anos apenas uma vez
    const currentYear = new Date().getFullYear();
    const generatedYears = Array.from({ length: 10 }, (_, i) => currentYear - i);
    setYears(generatedYears);
  }, []);

  useEffect(() => {
    // Buscar informações do usuário
    checkLoginStatus();
  }, [user_id]);

  return (
    <TVContainer>
      <HeaderContainer>
        <HeaderImg src={userImg} alt="User" />
        <HeaderMiddleContainer>
          <HeaderText>{mensagemTela}</HeaderText>
          {/* YearSelector desabilitado ou removido, já que não há interação na TV */}
        </HeaderMiddleContainer>
        <HeaderImg src={userImg} alt="User" />
      </HeaderContainer>

      {data.isLoading ? (
        <LoadingSpinner>Carregando...</LoadingSpinner>
      ) : data.noData ? (
        <NoDataContainer>
          <NoDataIcon />
          <NoDataText>
            Ainda não existem registros de pesagem para o ano <strong>{selectedYear}</strong>. 
            Por favor, verifique novamente mais tarde ou entre em contato com o suporte se acreditar que há um erro.
          </NoDataText>
        </NoDataContainer>
      ) : (
        <>
          <CardsContainer container spacing={4}>
            {data.impactCardsInfo.map((card) => (
              <Grid item xs={12} sm={6} md={3} key={card.id}>
                <ImpactCard impact={card.impact} text={card.subText} imageSrc={card.imageSrc} isTV={true} />
              </Grid>
            ))}
          </CardsContainer>

          <GraphsContainer>
            <BarGraphContainer>
              <ImpactBar
                data_1={data.totalCollectedByMonth}
                data_2={data.totalPlasticByMonth}
                data_3={data.totalGlassByMonth}
                data_4={data.totalMetalByMonth}
                data_5={data.totalPaperByMonth}
                data_6={data.totalOrganicByMonth}
                isTV={true}
                selectedYear={selectedYear} // Passando o ano selecionado
              />
            </BarGraphContainer>
            <PieGraphContainer>
              <ImpactPie labels={data.pieLabels} values={data.pieValues} isTV={true} />
            </PieGraphContainer>
          </GraphsContainer>
          <ImpactTitle>As emissões evitadas equivalem a:</ImpactTitle>
          <FooterContainer>
            <FooterImpactCardsContainer>
              <FooterImpactCard>
                <FooterImpactImage src={Carro} alt="Emissões Evitadas" />
                <FooterImpactTitle>Quilômetros Rodados em Carro de Médio Porte</FooterImpactTitle>
                <FooterImpactValue>{data.carKmEquivalent.toLocaleString('pt-BR')} km</FooterImpactValue>
              </FooterImpactCard>
              <FooterImpactCard>
                <FooterImpactImage src={Aviao} alt="Árvores Salvas" />
                <FooterImpactTitle>Quilômetros de Voo Comercial</FooterImpactTitle>
                <FooterImpactValue>{Math.round(data.planeKmEquivalent).toLocaleString('pt-BR')} km</FooterImpactValue>
              </FooterImpactCard>
              <FooterImpactCard>
                <FooterImpactImage src={Chuveiro} alt="Plásticos no Oceano" />
                <FooterImpactTitle>Horas de Chuveiro Elétrico Ligado</FooterImpactTitle>
                <FooterImpactValue>{data.showerHoursEquivalent.toLocaleString('pt-BR')} horas</FooterImpactValue>
              </FooterImpactCard>
              <FooterImpactCard>
                <FooterImpactImage src={ArCondicionado} alt="Total Reciclado" />
                <FooterImpactTitle>Horas de Ar Condicionado Ligado</FooterImpactTitle>
                <FooterImpactValue>{data.airConditionatorEquivalent.toLocaleString('pt-BR')} Horas</FooterImpactValue>
              </FooterImpactCard>
            </FooterImpactCardsContainer>

            <FooterInfoContainer>
              <FooterSiriContainer>
                <FooterLogo src={SiriLogoSvg} alt="Siri Logo" />
                <FooterText>Vem reciclar também!!!</FooterText>
              </FooterSiriContainer>
              <FooterSiriContainer>
                <FooterText>Escaneie o código para saber mais</FooterText>
                <QrCodeContainer>
                  <img src={QrCodeImage} width={100} height={100} alt="QR Code" />
                </QrCodeContainer>
              </FooterSiriContainer>
            </FooterInfoContainer>
          </FooterContainer>
        </>
      )}
    </TVContainer>
  );
}