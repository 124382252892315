import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  BarElement,
  LineElement,
  LineController,
  BarController
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import styled from 'styled-components';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  BarElement,
  LineElement,
  LineController,
  BarController
);

const BarDashboardContainer = styled.div<{ isTV: boolean }>`
  width: 100%;
  height: ${({ isTV }) => (isTV ? '600px' : '400px')};
  padding: 10px;

  @media (max-width: 900px) {
    height: ${({ isTV }) => (isTV ? '400px' : '300px')};
  }

  @media (min-width: 1921px) {
    height: ${({ isTV }) => (isTV ? '800px' : '500px')};
  }
`;

interface ImpactBarProps {
  data_1?: number[];
  data_2?: number[];
  data_3?: number[];
  data_4?: number[];
  data_5?: number[];
  data_6?: number[];
  isTV?: boolean;
  selectedYear: number; // Prop obrigatória
}

const ImpactBar: React.FC<ImpactBarProps> = ({
  data_1 = [],
  data_2 = [],
  data_3 = [],
  data_4 = [],
  data_5 = [],
  data_6 = [],
  isTV = false,
  selectedYear
}) => {
  const baseLabels = [
    'Janeiro','Fevereiro','Março','Abril','Maio','Junho',
    'Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'
  ];

  // Garante array de 12 posições
  const ensure12Months = (arr: number[]) => {
    if (arr.length < 12) {
      return [...arr, ...Array(12 - arr.length).fill(0)];
    }
    return arr.slice(0, 12);
  };

  // Passo 1: Garante 12 posições
  const finalizedData1 = ensure12Months(data_1);
  const finalizedData2 = ensure12Months(data_2);
  const finalizedData3 = ensure12Months(data_3);
  const finalizedData4 = ensure12Months(data_4);
  const finalizedData5 = ensure12Months(data_5);
  const finalizedData6 = ensure12Months(data_6);

  // Passo 2: Descobrir qual o primeiro e último mês com dado > 0,
  // em qualquer um dos 6 arrays.
  // Se não achar nada, mostrará tudo (ou nenhum).
  function findFirstNonZero(): number {
    for (let i = 0; i < 12; i++) {
      if (
        finalizedData1[i] > 0 ||
        finalizedData2[i] > 0 ||
        finalizedData3[i] > 0 ||
        finalizedData4[i] > 0 ||
        finalizedData5[i] > 0 ||
        finalizedData6[i] > 0
      ) {
        return i;
      }
    }
    return -1; // não achou
  }
  function findLastNonZero(): number {
    for (let i = 11; i >= 0; i--) {
      if (
        finalizedData1[i] > 0 ||
        finalizedData2[i] > 0 ||
        finalizedData3[i] > 0 ||
        finalizedData4[i] > 0 ||
        finalizedData5[i] > 0 ||
        finalizedData6[i] > 0
      ) {
        return i;
      }
    }
    return -1; 
  }

  const firstIndexWithData = findFirstNonZero();
  const lastIndexWithData  = findLastNonZero();

  // Se não achou dado em nenhum mês
  let filteredLabels   = baseLabels;
  let filteredData1    = finalizedData1;
  let filteredData2    = finalizedData2;
  let filteredData3    = finalizedData3;
  let filteredData4    = finalizedData4;
  let filteredData5    = finalizedData5;
  let filteredData6    = finalizedData6;

  if (firstIndexWithData !== -1 && lastIndexWithData !== -1 && firstIndexWithData <= lastIndexWithData) {
    filteredLabels = baseLabels.slice(firstIndexWithData, lastIndexWithData + 1);
    filteredData1  = finalizedData1.slice(firstIndexWithData, lastIndexWithData + 1);
    filteredData2  = finalizedData2.slice(firstIndexWithData, lastIndexWithData + 1);
    filteredData3  = finalizedData3.slice(firstIndexWithData, lastIndexWithData + 1);
    filteredData4  = finalizedData4.slice(firstIndexWithData, lastIndexWithData + 1);
    filteredData5  = finalizedData5.slice(firstIndexWithData, lastIndexWithData + 1);
    filteredData6  = finalizedData6.slice(firstIndexWithData, lastIndexWithData + 1);
  }

  const datasets = [
    {
      type: 'bar' as const,
      label: 'Plástico',
      data: filteredData2,
      backgroundColor: '#f72e03',
    },
    {
      type: 'bar' as const,
      label: 'Vidro',
      data: filteredData3,
      backgroundColor: '#37f703',
    },
    {
      type: 'bar' as const,
      label: 'Metais',
      data: filteredData4,
      backgroundColor: '#f7f303',
    },
    {
      type: 'bar' as const,
      label: 'Papel e Papelão',
      data: filteredData5,
      backgroundColor: '#0337f7',
    },
    {
      type: 'bar' as const,
      label: 'Orgânicos',
      data: filteredData6,
      backgroundColor: '#8B4513',
    },
    {
      type: 'line' as const,
      label: 'Total Coletado',
      data: filteredData1,
      borderColor: '#000000',
      borderWidth: 2,
      pointBackgroundColor: '#000000',
      fill: false,
    }
  ];

  const data = {
    labels: filteredLabels,
    datasets
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 15,
          padding: 20,
          font: {
            size: isTV ? 18 : 14,
            weight: 'bold'
          }
        }
      },
      datalabels: {
        display: false
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          font: {
            size: isTV ? 18 : 14
          }
        },
        title: {
          display: true,
          text: `${selectedYear}`,
          font: {
            size: isTV ? 20 : 16,
            weight: 'bold'
          }
        }
      },
      y: {
        ticks: {
          font: {
            size: isTV ? 18 : 14,
            weight: 'bold'
          },
          callback: function(value: number) {
            return `${value} Kg`;
          }
        },
        title: {
          display: true,
          text: 'Total Destinado (Kg)',
          font: {
            size: isTV ? 20 : 16,
            weight: 'bold'
          }
        }
      }
    }
  };

  return (
    <BarDashboardContainer isTV={isTV}>
      <Chart type='bar' data={data} options={options} />
    </BarDashboardContainer>
  );
};

export default ImpactBar;