import NavigationBar from './NavigationBar';
import MainHeader from './MainHeader';
import { useContext } from 'react';
import { ToggleMenuContext } from '../contexts/AppContext';
import { ReactComponent as Users } from '../assets/menuIcons/Users.svg';
// import { ReactComponent as Companies } from '../assets/menuIcons/Companies.svg';
import styled from 'styled-components';

import { ReactComponent as VisaoGeral } from '../assets/menuIcons/VisaoGeral.svg';

import { ReactComponent as UserSub } from '../assets/menuIcons/mingcute_user-5-line.svg';
import { ReactComponent as UserEdit } from '../assets/menuIcons/UserEdit.svg';

import { ReactComponent as Empresas } from '../assets/menuIcons/Empresas.svg';
import { ReactComponent as Empresa } from '../assets/menuIcons/Empresa.svg';
import { ReactComponent as CriarEmpresa } from '../assets/menuIcons/CriarEmpresa.svg';
import { ReactComponent as AreaAtendEmp } from '../assets/menuIcons/AreaAtendimentoEmp.svg';
import { ReactComponent as Vehicles } from '../assets/menuIcons/Vehicle.svg';
import { ReactComponent as Servicos } from '../assets/menuIcons/Servicos.svg';
import { ReactComponent as Ecoponto } from '../assets/menuIcons/Ecoponto.svg';

import { ReactComponent as Coletas } from '../assets/menuIcons/Coletas.svg';
import { ReactComponent as CriarColeta } from '../assets/menuIcons/CriarColetaNew.svg';
import { ReactComponent as ColetasPainel } from '../assets/menuIcons/ColetasPainel.svg';

import { ReactComponent as PaineldeRotas } from '../assets/menuIcons/PaineldeRotas.svg';
import { ReactComponent as RotasAgendadas } from '../assets/menuIcons/RotasAgendadas.svg';
import { ReactComponent as CriarRota } from '../assets/menuIcons/CriarRota.svg';

import { ReactComponent as Residuos } from '../assets/menuIcons/Residuos.svg';
import { ReactComponent as ResiduosTipos } from '../assets/menuIcons/ResiduosTipos.svg';
import { ReactComponent as Dashboard } from '../assets/menuIcons/dashboard.svg';
import { ReactComponent as Bar } from '../assets/menuIcons/bar.svg';
import { ReactComponent as Tv } from '../assets/menuIcons/tv.svg';
import { BalanceTwoTone, FireTruck, FolderDelete, TakeoutDining, TypeSpecimen, AcUnit, Forest } from '@mui/icons-material';
import { getAuthUser } from '../services/auth';




const Container = styled.div`
  max-width: 100vw;
  max-height: 100vh;
`;

const Main = styled.main`
  margin-left: ${(props) => (props.isActive ? '271px' : '96px')};
  margin-top: 6vh;
  transition: 300ms;
  display: flex;
  flex-direction: column;
  height: 94vh;
  max-height: 94vh;
`;

// import { useLocation } from 'react-router-dom';

function PageStructure({ children }) {
  const [navDisplay] = useContext(ToggleMenuContext);
  const authUser = getAuthUser();
  const user_id = authUser?.id;  // ou user.id, se tiver certeza

  // const location = useLocation().pathname;

  const navCards = [
    {
      name: 'Visão Geral',
      url: '/',
      users: ['all'],
      companies: ['all'],
      icon: <VisaoGeral />
    },
    {
      name: 'Relatórios',
      url: '#',
      users: ['Gerador Comercial'],
      companies: ['all'],
      icon: <Dashboard />,
      subCards: [
        {
          name: 'Relatório Anual',
          url: `/relatorio/${user_id}`,
          users: ['Gerador Comercial'],
          companies: ['all'],
          icon: <Bar />
        },
        {
          name: 'Relatório Anual para TV',
          url: `/relatorio-tv/${user_id}`,
          users: ['Gerador Comercial'],
          companies: ['all'],
          icon: <Tv />
        },
      ]
    },
    {
      name: 'Gestão de usuários',
      url: '#',
      users: ['Super Admin', 'Admin', 'Operador'],
      companies: ['all'],
      icon: <Users />,
      subCards: [
        {
          name: 'Usuários',
          url: '/users',
          users: ['Super Admin', 'Admin', 'Operador'],
          companies: ['all'],
          icon: <UserSub />
        },
        {
          name: 'Criar novo usuário',
          url: '/users/0',
          users: ['Super Admin', 'Admin', 'Operador'],
          companies: ['all'],
          icon: <UserEdit />
        },
        {
          name: 'Planos',
          url: '/plans',
          users: ['Super Admin', 'Admin', 'Operador'],
          companies: ['all'],
          icon: <TypeSpecimen />
        }
      ]
    },
    {
      name: 'Gestão de empresas',
      url: '#',
      users: ['Super Admin'],
      companies: ['all'],
      icon: <Empresas />,
      subCards: [
        {
          name: 'Empresas',
          url: '/companies',
          users: ['Super Admin'],
          companies: ['all'],
          icon: <Empresa />
        },
        {
          name: 'Criar nova empresa',
          url: '/companies/0',
          users: ['Super Admin'],
          companies: ['all'],
          icon: <CriarEmpresa />
        },
        {
          name: 'Serviços',
          url: '/packs',
          users: ['Super Admin'],
          companies: ['all'],
          icon: <Servicos />
        },
        {
          name: 'Área de atendimento',
          url: '/neighborhoods',
          users: ['Super Admin'],
          companies: ['all'],
          icon: <AreaAtendEmp />
        },
        {
          name: 'Veículos',
          url: '/vehicles',
          users: ['Super Admin'],
          companies: ['all'],
          icon: <Vehicles />
        }
      ]
    },
    {
      name: 'Gestão da empresas',
      url: '#',
      users: ['Admin', 'Operador'],
      companies: ['all'],
      icon: <Empresas />,
      subCards: [
        {
          name: 'Serviços',
          url: '/packs',
          users: ['Admin', 'Operador'],
          companies: ['all'],
          icon: <Servicos />
        },
        {
          name: 'Área de atendimento',
          url: '/neighborhoods',
          users: ['Admin', 'Operador'],
          companies: ['all'],
          icon: <AreaAtendEmp />
        },
        {
          name: 'Veículos',
          url: '/vehicles',
          users: ['Admin', 'Operador'],
          companies: ['all'],
          icon: <Vehicles />
        },
        {
          name: 'Ecopontos',
          url: '/companies',
          users: ['Admin', 'Operador'],
          companies: ['all'],
          icon: <Ecoponto />
        },
        {
          name: 'Criar Ecopontos',
          url: '/companies/0',
          users: ['Admin', 'Operador'],
          companies: ['all'],
          icon: <CriarColeta />
        }
      ]
    },
    {
      name: 'Coletas',
      url: '#',
      users: ['all'],
      companies: ['Coletadora'],
      icon: <Coletas />,
      subCards: [
        {
          name: 'Histórico de Coletas ',
          url: '/collects_events',
          users: ['all'],
          companies: ['all'],
          icon: <ColetasPainel />
        },
        {
          name: 'Coletas Requisitadas',
          url: '/collects',
          users: ['all'],
          companies: ['all'],
          icon: <RotasAgendadas />
        },
        {
          name: 'Criar nova coleta',
          url: '/collects/0',
          users: ['all'],
          companies: ['all'],
          icon: <CriarColeta />
        }
      ]
    },
    {
      name: 'Gestão de Rotas',
      url: '#',
      users: ['Super Admin', 'Admin', 'Operador', 'Catador'],
      companies: ['Coletadora'],
      icon: <PaineldeRotas />,
      subCards: [
        {
          name: 'Rotas Agendadas',
          url: '/routes_events',
          users: ['Super Admin', 'Admin', 'Operador', 'Catador'],
          companies: ['Coletadora'],
          icon: <RotasAgendadas />
        },
        {
          name: 'Painel de Rotas',
          url: '/routes',
          users: ['Super Admin', 'Admin', 'Operador', 'Catador'],
          companies: ['Coletadora'],
          icon: <PaineldeRotas />
        },
        {
          name: 'Criar Rota',
          url: '/routes/0',
          users: ['Super Admin', 'Admin', 'Operador', 'Catador'],
          companies: ['Coletadora'],
          icon: <CriarRota />
        }
      ]
    },
    {
      name: 'Resíduos',
      url: '#',
      users: ['Super Admin', 'Admin', 'Operador', 'Catador'],
      companies: ['Coletadora'],
      icon: <Residuos />,
      subCards: [
        {
          name: 'Cadastro de Resíduos',
          url: '/residues',
          users: ['Super Admin'],
          companies: ['Coletadora'],
          icon: <ResiduosTipos />
        },
        {
          name: 'Pesagem de Resíduos',
          url: '/weighings',
          users: ['Super Admin', 'Admin', 'Operador', 'Catador'],
          companies: ['Coletadora'],
          icon: <BalanceTwoTone color="black" />
        },
        {
          name: 'Unidades',
          url: '/units',
          users: ['Super Admin'],
          companies: ['Coletadora'],
          icon: <TypeSpecimen color="black" />
        },
        {
          name: 'Classes de Resíduos',
          url: '/residue_classes',
          users: ['Super Admin'],
          companies: ['Coletadora'],
          icon: <FolderDelete color="black" />
        },
        {
          name: 'Estados de Resíduos',
          url: '/residue_states',
          users: ['Super Admin'],
          companies: ['Coletadora'],
          icon: <AcUnit color="black" />
        },
        {
          name: 'Acondicionamentos',
          url: '/containers',
          users: ['Super Admin'],
          companies: ['Coletadora'],
          icon: <TakeoutDining color="black" />
        },
        {
          name: 'Tec.Tratamentos',
          url: '/treatments',
          users: ['Super Admin'],
          companies: [],
          icon: <FireTruck color="black" />
        },
        {
          name: 'Impactos Positivos',
          url: '/positive_impacts',
          users: ['Super Admin'],
          companies: [],
          icon: <Forest color="black" />
        }
      ]
    }
  ];

  return (
    <>
      <MainHeader></MainHeader>
      <Container>
        <NavigationBar navCards={navCards} />
        <Main isActive={navDisplay}>{children}</Main>
      </Container>
    </>
  );
}

export default PageStructure;
