// src/hooks/useReportData.tsx

import { useEffect, useState } from 'react';
import CollectTreatmentService from '../services/CollectTreatmentService';
import CollectResiduesService from '../services/CollectResiduesService';

interface ImpactCardInfo {
  id: number;
  impact: string;
  subText: string;
  imageSrc: string;
}

interface PieDashBoardInfo {
  title: string;
  value: number;
  percent: number;
}

interface ReportData {
  impactCardsInfo: ImpactCardInfo[];
  totalCollectedByMonth: number[];
  totalPlasticByMonth: number[];
  totalGlassByMonth: number[];
  totalMetalByMonth: number[];
  totalPaperByMonth: number[];
  totalOrganicByMonth: number[];
  planeKmEquivalent: number;
  showerHoursEquivalent: number;
  airConditionatorEquivalent: number;
  carKmEquivalent: number;
  pieLabels: string[];
  pieValues: number[];
  noData: boolean;
  isLoading: boolean;
}

const impactCardsTemplate: ImpactCardInfo[] = [
  { id: 0, impact: '', subText: 'Total de Resíduos Destinados', imageSrc: '/icons/Reciclagem.png' },
  { id: 1, impact: '', subText: 'Emissões Evitadas', imageSrc: '/icons/Co2avoid.png' },
  { id: 2, impact: '', subText: 'Árvores Salvas', imageSrc: '/icons/ArvoresSalvas.png' },
  { id: 3, impact: '', subText: 'Plásticos Evitados nos Oceanos', imageSrc: '/icons/PlasticoNoOceano.png' },
];

const useReportData = (user_id: number, year: number) => {
  const [data, setData] = useState<ReportData>({
    impactCardsInfo: impactCardsTemplate,
    totalCollectedByMonth: Array(12).fill(0),
    totalPlasticByMonth: Array(12).fill(0),
    totalGlassByMonth: Array(12).fill(0),
    totalMetalByMonth: Array(12).fill(0),
    totalPaperByMonth: Array(12).fill(0),
    totalOrganicByMonth: Array(12).fill(0),
    planeKmEquivalent: 0,
    showerHoursEquivalent: 0,
    airConditionatorEquivalent: 0,
    carKmEquivalent: 0,
    pieLabels: [],
    pieValues: [],
    noData: false,
    isLoading: true,
  });

  const formatNumber = (num: number) => Math.round(num).toLocaleString('pt-BR');

  useEffect(() => {
    const fetchData = async () => {
      setData(prev => ({ ...prev, isLoading: true }));

      try {
        /** 1) Buscando dados do ano inteiro para as emissões (CollectTreatmentService). */
        const initDate = `${year}-01-01`;
        const endDate = `${year}-12-31`;

        // Busca global de emissões e afins:
        const collectTreatmentsInfo = await CollectTreatmentService.getByMonth(user_id, initDate, endDate);

        /** 2) Buscando dados MÊS A MÊS (12 chamadas) para os resíduos (CollectResiduesService). */
        const months = [
          '01','02','03','04','05','06','07','08','09','10','11','12'
        ];

        // Função auxiliar para buscar dados de cada mês
        const fetchResiduesForMonth = async (month: string) => {
          // Ex: '2023-03-01' ~ '2023-03-31'
          const monthInit = `${year}-${month}-01`;
          const monthEnd  = `${year}-${month}-31`;
          const dataMonth = await CollectResiduesService.getByMonth(user_id, monthInit, monthEnd);
          // Se não for array, retorna array vazio
          return Array.isArray(dataMonth) ? dataMonth : [];
        };

        // Faz 12 chamadas em paralelo
        const residuesPromises = months.map(fetchResiduesForMonth);
        const collectResiduesMonthly = await Promise.all(residuesPromises);

        // Agora collectResiduesMonthly é um array de 12 itens (um para cada mês),
        // cada item também é um array com os dados de resíduos do respectivo mês.

        // Verificar se tudo está vazio
        const anyMonthHasData = collectTreatmentsInfo.length > 0 
          || collectResiduesMonthly.some(arr => arr.length > 0);

        if (!anyMonthHasData) {
          // Se não tem dados nem no Treatments nem nos Residues, marca como noData
          setData(prev => ({
            ...prev,
            noData: true,
            isLoading: false,
            impactCardsInfo: impactCardsTemplate,
            totalCollectedByMonth: Array(12).fill(0),
            totalPlasticByMonth: Array(12).fill(0),
            totalGlassByMonth: Array(12).fill(0),
            totalMetalByMonth: Array(12).fill(0),
            totalPaperByMonth: Array(12).fill(0),
            totalOrganicByMonth: Array(12).fill(0),
            planeKmEquivalent: 0,
            carKmEquivalent: 0,
            airConditionatorEquivalent: 0,
            showerHoursEquivalent: 0,
            pieLabels: [],
            pieValues: [],
          }));
          return;
        }

        /** 3) Processando emissões (CollectTreatmentsInfo) para extrair emissões evitadas, etc. */
        const totalAvoidCO2 = collectTreatmentsInfo.reduce(
          (acc: number, item: any) => acc + (item.positive_impact?.avoid_co2 || 0),
          0
        );

        const planeKmEquivalent      = totalAvoidCO2 / 0.115;
        const carKmEquivalent        = totalAvoidCO2 / 0.2;
        const airConditionatorEquivalent = totalAvoidCO2 / 0.56;
        const showerHoursEquivalent  = totalAvoidCO2;

        let mainCardsInfo = [...impactCardsTemplate];
        // Árvores Salvas (index 2):
        mainCardsInfo[2].impact = formatNumber(Math.round(totalAvoidCO2 / 168));
        // Emissões Evitadas (index 1):
        mainCardsInfo[1].impact = `${formatNumber(totalAvoidCO2)} kg CO₂`;

        /** 4) Somando todos os resíduos do ano para gerar o gráfico de pizza e impactCards[0/3]. */
        // Juntamos todas as 12 lists em um único array
        const allResiduesYear = collectResiduesMonthly.flat(); // .flat() retorna tudo num array só

        // Processa "pizza"
        const validTitles = ['Vidro', 'Papel', 'Plásticos', 'Metais'];
        const validCollectTreatments = allResiduesYear.reduce<PieDashBoardInfo[]>((acc, obj: any) => {
          const titleTrimmed = obj.title ? obj.title.trim().split(' ')[0] : 'Outros';
          if (validTitles.includes(titleTrimmed)) {
            const exist = acc.find(item => item.title === titleTrimmed);
            if (exist) {
              exist.value   += obj.value;
              exist.percent += obj.percent;
            } else {
              acc.push({ title: titleTrimmed, value: obj.value, percent: obj.percent });
            }
          } else {
            // "Outros"
            const otherItem = acc.find(item => item.title === 'Outros');
            if (otherItem) {
              otherItem.value += obj.value;
            } else {
              acc.push({ title: 'Outros', value: obj.value, percent: 0 });
            }
          }
          return acc;
        }, []);

        const totalWeight = validCollectTreatments.reduce((acc, item) => acc + item.value, 0);
        // impactCards[0] => "Total de Resíduos Destinados"
        mainCardsInfo[0].impact = `${formatNumber(Math.round(totalWeight))} kg`;

        // Se existe Plástico
        const totalPlastic = validCollectTreatments.find(item => item.title === 'Plásticos') || { value: 0 };
        // impactCards[3] => "Plásticos Evitados nos Oceanos"
        mainCardsInfo[3].impact = `${formatNumber(Math.round(totalPlastic.value * 0.3))} kg`;

        /** 5) Agora popula arrays mensais (collectedByMonth, plasticByMonth, etc.) */
        // Precisamos iterar sobre collectResiduesMonthly (12 arrays, um p/ cada mês)
        const collectedByMonth = Array(12).fill(0);
        const plasticByMonth   = Array(12).fill(0);
        const glassByMonth     = Array(12).fill(0);
        const metalByMonth     = Array(12).fill(0);
        const paperByMonth     = Array(12).fill(0);
        const organicByMonth   = Array(12).fill(0);

        collectResiduesMonthly.forEach((monthlyArray, index) => {
          // monthlyArray = dados do index-ésimo mês (0=Janeiro, 1=Fevereiro, etc.)
          const sumMonth = monthlyArray.reduce((acc, residue: any) => acc + (residue.value || 0), 0);
          collectedByMonth[index] = sumMonth;

          monthlyArray.forEach((residue: any) => {
            const firstWord = residue.title?.trim().split(' ')[0] ?? '';
            switch (firstWord) {
              case 'Plásticos':
                plasticByMonth[index] += residue.value || 0;
                break;
              case 'Vidro':
                glassByMonth[index]   += residue.value || 0;
                break;
              case 'Metais':
                metalByMonth[index]   += residue.value || 0;
                break;
              case 'Papel':
                paperByMonth[index]   += residue.value || 0;
                break;
              case 'Orgânicos':
                organicByMonth[index] += residue.value || 0;
                break;
            }
          });
        });

        /** 6) Com tudo processado, atualiza o estado final no hook. */
        setData(prev => ({
          ...prev,
          noData: false,
          isLoading: false,
          impactCardsInfo: mainCardsInfo,
          totalCollectedByMonth: collectedByMonth,
          totalPlasticByMonth: plasticByMonth,
          totalGlassByMonth:   glassByMonth,
          totalMetalByMonth:   metalByMonth,
          totalPaperByMonth:   paperByMonth,
          totalOrganicByMonth: organicByMonth,
          planeKmEquivalent,
          carKmEquivalent,
          airConditionatorEquivalent,
          showerHoursEquivalent,
          pieLabels: validCollectTreatments.map(item => item.title),
          pieValues: validCollectTreatments.map(item => item.value),
        }));

      } catch (error) {
        console.error('Erro ao buscar dados de impacto positivo:', error);

        // Estado de fallback se ocorrer erro
        setData(prev => ({
          ...prev,
          noData: true,
          isLoading: false,
          impactCardsInfo: impactCardsTemplate,
          totalCollectedByMonth: Array(12).fill(0),
          totalPlasticByMonth: Array(12).fill(0),
          totalGlassByMonth: Array(12).fill(0),
          totalMetalByMonth: Array(12).fill(0),
          totalPaperByMonth: Array(12).fill(0),
          totalOrganicByMonth: Array(12).fill(0),
          planeKmEquivalent: 0,
          showerHoursEquivalent: 0,
          airConditionatorEquivalent: 0,
          carKmEquivalent: 0,
          pieLabels: [],
          pieValues: [],
        }));
      }
    };

    fetchData();
  }, [user_id, year]);

  return { data, setData };
};

export default useReportData;