// src/pages/Report/ReportWeb.tsx

import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import ImpactPie from '../../components/ImpactPie';
import ImpactBar from '../../components/ImpactBar';
import ImpactCard from '../../components/ImpactCard';
import useReportData from '../../hooks/useReportData';
import { useParams } from 'react-router-dom';
import UserService from '../../services/UserService';
import { isAuthenticated } from '../../services/auth';
import { baseImg } from '../../App';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import PageStructure from '../../components/PageStructure';
import { PageContainer, PageContent, PageFooter } from '../../components/GlobalStyleds';

// --- Styled Components ---
const DisplayContainer = styled.div`
  width: 100%;
  background: url('/icons/dashboard-background.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;

  /* Ajuste para telas menores */
  @media (max-width: 900px) {
    /* Ajustes de responsividade se necessário */
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 2px solid #ccc;
`;

const HeaderMiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderText = styled.p`
  color: #ffffff;
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
  margin: 10px 0;

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1921px) {
    font-size: 3rem;
  }
`;

const HeaderImg = styled.img`
  width: 80px;
  height: 80px;

  @media (min-width: 768px) {
    width: 100px;
    height: 100px;
  }

  @media (min-width: 1921px) {
    width: 120px;
    height: 120px;
  }
`;

const CardsContainerStyled = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 10px 0;
`;

const GraphsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: stretch;
  }

  @media (min-width: 1921px) {
    height: 60%;
  }
`;

const BarGraphContainer = styled.div`
  flex: 0.7;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    padding: 10px;
    margin: 5px;
  }

  @media (min-width: 1921px) {
    padding: 25px;
    margin: 15px;
  }
`;

const PieGraphContainer = styled.div`
  flex: 0.3;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    padding: 10px;
    margin: 5px;
  }

  @media (min-width: 1921px) {
    padding: 25px;
    margin: 15px;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FooterImpactCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  flex: 0.8;

  @media (min-width: 768px) {
    justify-content: space-around;
  }
`;

const FooterInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 0.2;
  flex-wrap: wrap;
  min-width: 250px;
  margin: 10px;
`;

const FooterSiriContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 250px;
  margin: 10px;
`;

const FooterImpactCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;

  @media (min-width: 768px) {
    width: 200px;
  }
`;

const FooterImpactImage = styled.img`
  width: 50px;
  height: 50px;

  @media (min-width: 768px) {
    width: 80px;
    height: 80px;
  }

  @media (min-width: 1921px) {
    width: 100px;
    height: 100px;
  }
`;

const FooterImpactTitle = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  color: #666;
  margin-top: 5px;

  @media (min-width: 1921px) {
    font-size: 1rem;
  }
`;

const FooterImpactValue = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin-top: 5px;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }

  @media (min-width: 1921px) {
    font-size: 1.4rem;
  }
`;

const FooterLogo = styled.img`
  width: 80px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    width: 120px;
  }

  @media (min-width: 1921px) {
    width: 150px;
  }
`;

const FooterText = styled.p`
  font-size: 1rem;
  color: #ffffff;
  font-weight: bold;
  margin: 0;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }

  @media (min-width: 1921px) {
    font-size: 1.5rem;
  }
`;

const QrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const ImpactTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  margin-top: 8px;

  @media (min-width: 1921px) {
    font-size: 1.1rem;
  }
`;

// Mensagem de ausência de dados
const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  border: 2px dashed #ff0000;
`;

const NoDataIcon = styled(AiOutlineExclamationCircle)`
  color: #ff0000;
  font-size: 3rem;
  margin-bottom: 10px;
`;

const NoDataText = styled.p`
  font-size: 1.5rem;
  color: #ffffff;
  text-align: center;
  max-width: 600px;

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }

  @media (min-width: 1921px) {
    font-size: 2rem;
  }
`;

const YearSelector = styled.select`
  padding: 8px;
  font-size: 1rem;
  border-radius: 4px;
  margin-top: 10px;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 0.8rem;
    margin-top: 5px;
  }

  @media (min-width: 1921px) {
    font-size: 1.2rem;
  }
`;

const LoadingSpinner = styled.div`
  font-size: 1.5rem;
  color: #ffffff;
  text-align: center;
  margin-top: 20px;
`;

const ExportButton = styled.button`
  padding: 10px 20px;
  background-color: #4caf50; /* Verde */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin: 20px 0;

  &:hover {
    background-color: #45a049;
  }

  @media (max-width: 480px) {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  @media (min-width: 1921px) {
    padding: 12px 24px;
    font-size: 1.2rem;
  }
`;

// Componente "ImpactCardComponent"
const ImpactCardComponent = ({
  impact,
  text,
  imageSrc,
  isTV,
}: {
  impact: string;
  text: string;
  imageSrc: string;
  isTV: boolean;
}) => <ImpactCard impact={impact} text={text} imageSrc={imageSrc} isTV={isTV} />;

interface ImpactCardInfo {
  id: number;
  impact: string;
  subText: string;
  imageSrc: string;
}

// Componente principal
export default function ReportWeb() {
  const { id } = useParams<{ id: string }>();
  const user_id = Number(id);

  const [userImg, setUserImg] = useState<string | undefined>(undefined);
  const [mensagemTela, setMensagemTela] = useState('');
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [years, setYears] = useState<number[]>([]);

  const { data } = useReportData(user_id, selectedYear);

  // Referência para o conteúdo do relatório
  const reportRef = useRef<HTMLDivElement>(null);

  // Ao montar
  useEffect(() => {
    // Define a lista de anos, por exemplo, últimos 2 anos
    const currentYear = new Date().getFullYear();
    const generatedYears = Array.from({ length: 2 }, (_, i) => currentYear - i);
    setYears(generatedYears);
  }, []);

  // Checar status de login e pegar info do usuário
  useEffect(() => {
    (async () => {
      if (isAuthenticated()) {
        const user = await UserService.getById(user_id);
        setUserImg(user.img ? baseImg + user.img : '/icons/default-user.png');
        setMensagemTela(`${user.name}: Resultados da Gestão de Resíduos Sólidos`);
      } else {
        // Não autenticado
        const user = await UserService.getBasicInfo(user_id);
        setUserImg(user.img ? baseImg + user.img : '/icons/default-user.png');
        setMensagemTela(`${user.name}: Resultados da Gestão de Resíduos Sólidos`);
      }
    })();
  }, [user_id]);

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(Number(e.target.value));
  };

  /**
   * Exporta para PDF usando um tamanho fixo (ex: 1500px de largura)
   * e altura proporcional, gerando só 1 página.
   */
  const exportToPDF = async () => {
    const input = reportRef.current;
    if (!input) {
      console.error('Não foi possível encontrar o conteúdo para exportar.');
      return;
    }

    // 1) Medir tamanho real do container
    const rect = input.getBoundingClientRect();
    const contentWidth = rect.width;
    const contentHeight = rect.height;

    if (contentWidth <= 0 || contentHeight <= 0) {
      console.error('Dimensões inválidas do conteúdo');
      return;
    }

    // 2) Define largura final do PDF (em px)
    const pdfWidth = 1500;
    // 3) Calcula escala e altura final
    const scale = pdfWidth / contentWidth;
    const pdfHeight = contentHeight * scale;

    // 4) Capturar com html2canvas
    //    scale: 2 => boa resolução
    const canvas = await html2canvas(input, {
      scale: 2,
      useCORS: true,
      backgroundColor: null, // Mantém background que for do DOM
    });

    // 5) Converte para imagem
    const imgData = canvas.toDataURL('image/png');

    // 6) Cria PDF com essas dimensões
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: [pdfWidth, pdfHeight],
    });

    // 7) Adiciona imagem no PDF
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

    // 8) Salva
    pdf.save(`Relatorio_Impacto_${selectedYear}.pdf`);
  };

  return (
    <PageStructure>
      <PageContainer>
        <PageContent>
          {/* Container para ser capturado */}
          <DisplayContainer ref={reportRef}>
            <HeaderContainer>
              <HeaderImg src={userImg} alt="User" />
              <HeaderMiddleContainer>
                <HeaderText>{mensagemTela}</HeaderText>
                <YearSelector value={selectedYear} onChange={handleYearChange}>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </YearSelector>
              </HeaderMiddleContainer>
              <HeaderImg src={userImg} alt="User" />
            </HeaderContainer>

            {data.isLoading ? (
              <LoadingSpinner>Carregando...</LoadingSpinner>
            ) : data.noData ? (
              <NoDataContainer>
                <NoDataIcon />
                <NoDataText>
                  Ainda não existem registros de pesagem para o ano <strong>{selectedYear}</strong>. 
                  Verifique novamente mais tarde ou contate o suporte.
                </NoDataText>
              </NoDataContainer>
            ) : (
              <>
                <CardsContainerStyled container spacing={2}>
                  {data.impactCardsInfo.map((card: ImpactCardInfo) => (
                    <Grid item xs={6} sm={3} key={card.id}>
                      <ImpactCardComponent
                        impact={card.impact}
                        text={card.subText}
                        imageSrc={card.imageSrc}
                        isTV={false}
                      />
                    </Grid>
                  ))}
                </CardsContainerStyled>

                <GraphsContainer>
                  <BarGraphContainer>
                    <ImpactBar
                      data_1={data.totalCollectedByMonth}
                      data_2={data.totalPlasticByMonth}
                      data_3={data.totalGlassByMonth}
                      data_4={data.totalMetalByMonth}
                      data_5={data.totalPaperByMonth}
                      data_6={data.totalOrganicByMonth}
                      selectedYear={selectedYear}
                      isTV={false}
                    />
                  </BarGraphContainer>

                  <PieGraphContainer>
                    <ImpactPie
                      labels={data.pieLabels}
                      values={data.pieValues}
                      isTV={false}
                    />
                  </PieGraphContainer>
                </GraphsContainer>

                <ImpactTitle>As emissões evitadas equivalentes:</ImpactTitle>

                <FooterContainer>
                  <FooterImpactCardsContainer>
                    <FooterImpactCard>
                      <FooterImpactImage src="/icons/Car.png" alt="Quilômetros Rodados" />
                      <FooterImpactTitle>Quilômetros Rodados em Carro de Médio Porte</FooterImpactTitle>
                      <FooterImpactValue>
                        {Math.round(data.carKmEquivalent).toLocaleString('pt-BR')} km
                      </FooterImpactValue>
                    </FooterImpactCard>

                    <FooterImpactCard>
                      <FooterImpactImage src="/icons/Plane.png" alt="Quilômetros de Voo" />
                      <FooterImpactTitle>Quilômetros de Voo Comercial</FooterImpactTitle>
                      <FooterImpactValue>
                        {Math.round(data.planeKmEquivalent).toLocaleString('pt-BR')} km
                      </FooterImpactValue>
                    </FooterImpactCard>

                    <FooterImpactCard>
                      <FooterImpactImage src="/icons/shower.png" alt="Horas de Chuveiro" />
                      <FooterImpactTitle>Horas de Chuveiro Elétrico Ligado</FooterImpactTitle>
                      <FooterImpactValue>
                        {Math.round(data.showerHoursEquivalent).toLocaleString('pt-BR')} horas
                      </FooterImpactValue>
                    </FooterImpactCard>

                    <FooterImpactCard>
                      <FooterImpactImage src="/icons/airConditionator.png" alt="Horas de Ar Condicionado" />
                      <FooterImpactTitle>Horas de Ar Condicionado Ligado</FooterImpactTitle>
                      <FooterImpactValue>
                        {Math.round(data.airConditionatorEquivalent).toLocaleString('pt-BR')} Horas
                      </FooterImpactValue>
                    </FooterImpactCard>
                  </FooterImpactCardsContainer>

                  <FooterInfoContainer>
                    <FooterSiriContainer>
                      <FooterLogo src="/icons/LogoSiri.png" alt="Siri Logo" />
                      <FooterText>Vem reciclar também!!!</FooterText>
                    </FooterSiriContainer>
                  </FooterInfoContainer>
                </FooterContainer>
              </>
            )}
          </DisplayContainer>

          {/* Botão de Exportação Fora do Conteúdo a Ser Exportado */}
          <ExportButton onClick={exportToPDF}>Exportar para PDF</ExportButton>
        </PageContent>
        <PageFooter></PageFooter>
      </PageContainer>
    </PageStructure>
  );
}