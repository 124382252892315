// src/pages/ImpactDisplayPage.jsx

import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import { AiOutlineExclamationCircle } from 'react-icons/ai'; // Importação do ícone
import SiriLogoSvg from '../../assets/LogoSiri.png';
import TreeImage from '../../assets/icons/ArvoresSalvas.png';
import Co2Image from '../../assets/icons/Co2avoid.png';
import OceanPlastic from '../../assets/icons/PlasticoNoOceano.png';
import Reciclagem from '../../assets/icons/Reciclagem.png';
import Carro from '../../assets/icons/Car.png';
import Aviao from '../../assets/icons/Plane.png';
import Chuveiro from '../../assets/icons/shower.png';
import ArCondicionado from '../../assets/icons/airConditionator.png';
import QrCodeImage from '../../assets/qr-code-siri.png';
import DashboardBackground from '../../assets/dashboard-background.jpg'; // Importação da imagem de fundo
import ImpactPie from '../../components/ImpactPie';
import ImpactBar from '../../components/ImpactBar';
import { useParams } from 'react-router-dom';
import CollectTreatmentService from '../../services/CollectTreatmentService';
import CollectResiduesService from '../../services/CollectResiduesService';
import UserService from '../../services/UserService';
import { isAuthenticated } from '../../services/auth';
import { baseImg } from '../../App';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

// Contêiner principal da página
const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Contêiner responsivo para visualização na web
const DisplayContainer = styled.div`
  width: 90%;
  max-width: 1200px;
  background: url(${DashboardBackground}) no-repeat center center;
  background-size: cover;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  
  /* Ajuste para telas menores */
  @media (max-width: 900px) {
    padding: 10px;
  }
`;

// Contêiner para exportação (oculto na web)
const ExportContainer = styled.div`
  width: 841.89px; /* Largura A4 em pontos para landscape */
  height: 595.28px; /* Altura A4 em pontos para landscape */
  background: url(${DashboardBackground}) no-repeat center center;
  background-size: cover;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  position: absolute;
  top: -9999px; /* Esconde o contêiner fora da visualização */
  left: -9999px;
  
  @media (max-width: 900px) {
    padding: 10px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 2px solid #ccc;
`;

const HeaderMiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderText = styled.p`
  color: #ffffff;
  font-size: 2rem;
  text-align: center; /* Alinhamento centralizado */
  font-weight: bold;
  margin: 10px 0; /* Espaçamento vertical */

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1921px) {
    font-size: 3rem;
  }
`;

const HeaderImg = styled.img`
  width: 80px;
  height: 80px;

  @media (min-width: 768px) {
    width: 100px;
    height: 100px;
  }

  @media (min-width: 1921px) {
    width: 120px;
    height: 120px;
  }
`;

const ImpactCardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 150px;
  margin: 5px;
`;

const ImpactCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
`;

const ImpactImage = styled.img`
  width: 50px;
  height: 50px;

  @media (min-width: 768px) {
    width: 80px;
    height: 80px;
  }

  @media (min-width: 1921px) {
    width: 100px;
    height: 100px;
  }
`;

const ImpactCardTitle = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  color: #666;

  @media (min-width: 768px) {
    font-size: 1rem;
  }

  @media (min-width: 1921px) {
    font-size: 1.2rem;
  }
`;

const ImpactValue = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 1.4rem;
  }

  @media (min-width: 1921px) {
    font-size: 1.6rem;
  }
`;

const CardsContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 10px 0;
`;

const GraphsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: stretch;
  }

  @media (min-width: 1921px) {
    height: 60%;
  }
`;

const BarGraphContainer = styled.div`
  flex: 0.7;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    padding: 10px;
    margin: 5px;
  }

  @media (min-width: 1921px) {
    padding: 25px;
    margin: 15px;
  }
`;

const PieGraphContainer = styled.div`
  flex: 0.3;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    padding: 10px;
    margin: 5px;
  }

  @media (min-width: 1921px) {
    padding: 25px;
    margin: 15px;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FooterImpactCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  flex: 0.6;

  @media (min-width: 768px) {
    justify-content: space-around;
  }
`;

const FooterInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 0.4;
  flex-wrap: wrap;
  min-width: 250px;
  margin: 10px;
`;

const FooterSiriContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 250px;
  margin: 10px;
`;

const FooterImpactCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;

  @media (min-width: 768px) {
    width: 200px;
  }
`;

const FooterImpactImage = styled.img`
  width: 50px;
  height: 50px;

  @media (min-width: 768px) {
    width: 80px;
    height: 80px;
  }

  @media (min-width: 1921px) {
    width: 100px;
    height: 100px;
  }
`;

const FooterImpactTitle = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  color: #666;
  margin-top: 5px;

  @media (min-width: 1921px) {
    font-size: 1rem;
  }
`;

const FooterImpactValue = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin-top: 5px;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }

  @media (min-width: 1921px) {
    font-size: 1.4rem;
  }
`;

const FooterLogo = styled.img`
  width: 80px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    width: 120px;
  }

  @media (min-width: 1921px) {
    width: 150px;
  }
`;

const FooterText = styled.p`
  font-size: 1rem;
  color: #ffffff;
  font-weight: bold;
  margin: 0;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }

  @media (min-width: 1921px) {
    font-size: 1.5rem;
  }
`;

const QrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const ImpactTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #666;
  text-align: center;
  margin-top: 8px;

  @media (min-width: 1921px) {
    font-size: 1.1rem;
  }
`;

// Componentes para a mensagem de ausência de dados
const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6); /* Fundo semitransparente para destaque */
  border-radius: 8px;
  border: 2px dashed #ff0000; /* Borda destacada */
`;

const NoDataIcon = styled(AiOutlineExclamationCircle)`
  color: #ff0000;
  font-size: 3rem;
  margin-bottom: 10px;
`;

const NoDataText = styled.p`
  font-size: 1.5rem;
  color: #ffffff;
  text-align: center;
  max-width: 600px;

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }

  @media (min-width: 1921px) {
    font-size: 2rem;
  }
`;

const YearSelector = styled.select`
  padding: 8px;
  font-size: 1rem;
  border-radius: 4px;
  margin-top: 10px; /* Espaçamento acima do seletor */
  justify-content: center;
  text-align: center;
  align-items: center; /* Corrige a propriedade */

  @media (max-width: 480px) {
    font-size: 0.8rem;
    margin-top: 5px;
  }

  @media (min-width: 1921px) {
    font-size: 1.2rem;
  }
`;

const LoadingSpinner = styled.div`
  font-size: 1.5rem;
  color: #ffffff;
  text-align: center;
  margin-top: 20px;
`;

// Botão de Exportação
const ExportButton = styled.button`
  padding: 10px 20px;
  background-color: #4caf50; /* Verde */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin: 20px 0; /* Espaçamento vertical */

  &:hover {
    background-color: #45a049;
  }

  @media (max-width: 480px) {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  @media (min-width: 1921px) {
    padding: 12px 24px;
    font-size: 1.2rem;
  }
`;

const impactCards = [
  { id: 0, impact: '', subText: 'Total de Resíduos Destinados', imageSrc: Reciclagem },
  { id: 1, impact: '', subText: 'Emissões Evitadas', imageSrc: Co2Image },
  { id: 2, impact: '', subText: 'Árvores Salvas', imageSrc: TreeImage },
  { id: 3, impact: '', subText: 'Plásticos Evitados nos Oceanos', imageSrc: OceanPlastic },
];

interface PieDashBoardInfo {
  title: string;
  value: number;
  percent: number;
}

const ImpactCard = ({ impact, text, imageSrc }: { impact: string; text: string; imageSrc: string }) => (
  <ImpactCardContainer>
    <ImpactImage src={imageSrc} alt={text} />
    <ImpactCardContent>
      <ImpactCardTitle>{text}</ImpactCardTitle>
      <ImpactValue>{impact}</ImpactValue>
    </ImpactCardContent>
  </ImpactCardContainer>
);

export default function ImpactDisplayPage() {
  const { id } = useParams<{ id: string }>();
  const user_id = Number(id);

  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [years, setYears] = useState<number[]>([]);
  const [totalWeight, setTotalWeight] = useState<number>(0);
  const [pieLabels, setPieLabels] = useState<string[]>([]);
  const [pieValues, setPieValues] = useState<number[]>([]);
  const [impactCardsInfo, setImpactCardsInfo] = useState(impactCards);
  const [totalCollectedByMonth, setTotalCollectedByMonth] = useState<number[]>([]);
  const [totalPlasticByMonth, setTotalPlasticByMonth] = useState<number[]>([]);
  const [totalGlassByMonth, setTotalGlassByMonth] = useState<number[]>([]);
  const [totalMetalByMonth, setTotalMetalByMonth] = useState<number[]>([]);
  const [totalPaperByMonth, setTotalPaperByMonth] = useState<number[]>([]);
  const [totalOrganicByMonth, setTotalOrganicByMonth] = useState<number[]>([]);
  const [planeKmEquivalent, setPlaneKmEquivalent] = useState<number>(0);
  const [showerHoursEquivalent, setShowerHoursEquivalent] = useState<number>(0);
  const [airConditionatorEquivalent, setAirConditionatorEquivalent] = useState<number>(0);
  const [carKmEquivalent, setCarKmEquivalent] = useState<number>(0);
  const [mensagemTela, setMensagemTela] = useState("");
  const [userName, setUserName] = useState<string | null>(null);
  const [userImg, setUserImg] = useState<string | undefined>(undefined);
  const [noData, setNoData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formatNumber = (num: number) => {
    return Math.round(num).toLocaleString('pt-BR');
  };

  // Referência para o conteúdo do relatório na web
  const reportRef = useRef<HTMLDivElement>(null);
  // Referência para o conteúdo do relatório na exportação
  const exportRef = useRef<HTMLDivElement>(null);

  // Função para exportar o relatório para PDF
  const exportToPDF = () => {
    const input = exportRef.current;
    if (!input) {
      console.error("Não foi possível encontrar o conteúdo para exportar.");
      return;
    }

    // Configuração do html2canvas
    html2canvas(input, {
      scale: 2, // Escala para melhor resolução
      useCORS: true,
      allowTaint: true,
      logging: true,
      backgroundColor: null, // Para capturar o background transparente se necessário
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'landscape', // Formato paisagem
          unit: 'pt',
          format: 'a4',
        });

        // Calcula as dimensões da imagem para ajustar ao PDF
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        // Verifica se a altura excede a página, adiciona nova página se necessário
        if (pdfHeight > pdf.internal.pageSize.getHeight() - 20) {
          const totalPages = Math.ceil(pdfHeight / (pdf.internal.pageSize.getHeight() - 20));
          for (let i = 0; i < totalPages; i++) {
            const sourceY = i * (pdf.internal.pageSize.getHeight() - 20);
            const pageHeight = pdf.internal.pageSize.getHeight() - 20;
            const pageCanvas = document.createElement('canvas');
            pageCanvas.width = canvas.width;
            pageCanvas.height = pageHeight * (canvas.width / pdfWidth);
            const pageCtx = pageCanvas.getContext('2d');
            pageCtx?.drawImage(canvas, 0, sourceY, canvas.width, pageHeight * (canvas.width / pdfWidth), 0, 0, canvas.width, pageHeight * (canvas.width / pdfWidth));
            const pageData = pageCanvas.toDataURL('image/png');
            if (i > 0) pdf.addPage();
            pdf.addImage(pageData, 'PNG', 10, 10, pdfWidth - 20, pageHeight - 20);
          }
        } else {
          // Adiciona a imagem ao PDF com margens de 10pt
          pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth - 20, pdfHeight - 20);
        }

        // Salva o PDF
        pdf.save(`Relatorio_Impacto_${selectedYear}.pdf`);
      })
      .catch((error) => {
        console.error("Erro ao exportar para PDF:", error);
      });
  };

  async function checkLoginStatus() {
    try {
      if (isAuthenticated()) {
        const user = await UserService.getById(user_id);
        setUserName(user.name);
        setUserImg(user.img ? baseImg + user.img : '');
        setMensagemTela(`${user.name}: Resultados da Gestão de Resíduos Sólidos`);
        return true;
      } else {
        const user = await UserService.getBasicInfo(user_id);
        setUserName(user.name);
        setUserImg(user.img ? baseImg + user.img : '');
        setMensagemTela(`${user.name}: Resultados da Gestão de Resíduos Sólidos`);
        return false;
      }
    } catch (error) {
      console.error("Erro ao verificar status de login:", error);
    }
  }

  async function getPositiveImpact(year: number) {
    setIsLoading(true);
    try {
      const initDate = `${year}-01-01`;
      const endDate = `${year}-12-31`;

      const [collectTreatmentsInfo, collectResidues] = await Promise.all([
        CollectTreatmentService.getByMonth(user_id, initDate, endDate),
        CollectResiduesService.getByMonth(user_id, initDate, endDate),
      ]);

      if ((!collectTreatmentsInfo || collectTreatmentsInfo.length === 0) &&
          (!collectResidues || collectResidues.length === 0)) {
        setNoData(true);
        setImpactCardsInfo(impactCards); // Resetar os cards
        setTotalWeight(0);
        setPieLabels([]);
        setPieValues([]);
        // Resetar outras métricas se necessário
        setTotalCollectedByMonth([]);
        setTotalPlasticByMonth([]);
        setTotalGlassByMonth([]);
        setTotalMetalByMonth([]);
        setTotalPaperByMonth([]);
        setTotalOrganicByMonth([]);
        setPlaneKmEquivalent(0);
        setCarKmEquivalent(0);
        setAirConditionatorEquivalent(0);
        setShowerHoursEquivalent(0);
        return;
      }

      setNoData(false);

      const totalAvoidCO2 = collectTreatmentsInfo.reduce((acc, item) => acc + item.positive_impact.avoid_co2, 0);

      setPlaneKmEquivalent(totalAvoidCO2 / 0.115);
      setCarKmEquivalent(totalAvoidCO2 / 0.2);
      setAirConditionatorEquivalent(totalAvoidCO2 / 0.56);
      setShowerHoursEquivalent(totalAvoidCO2);

      let mainCardsInfo = [...impactCards];
      mainCardsInfo[2].impact = formatNumber(Math.round(totalAvoidCO2 / 168));
      mainCardsInfo[1].impact = formatNumber(totalAvoidCO2) + " kg CO₂-e";

      const validTitles = ['Vidro', 'Papel', 'Plásticos', 'Metais'];
      const validCollectTreatments = collectResidues.reduce<PieDashBoardInfo[]>((acc, obj) => {
        const titleTrimmed = obj.title ? obj.title.trim().split(' ')[0] : 'Outros';
        if (validTitles.includes(titleTrimmed)) {
          const exist = acc.find((item) => item.title === titleTrimmed);
          if (exist) {
            exist.value += obj.value;
            exist.percent += obj.percent;
          } else {
            acc.push({ title: titleTrimmed, value: obj.value, percent: obj.percent });
          }
        } else {
          const otherItem = acc.find((item) => item.title === 'Outros');
          if (otherItem) {
            otherItem.value += obj.value;
          } else {
            acc.push({ title: 'Outros', value: obj.value, percent: obj.percent });
          }
        }
        return acc;
      }, []);

      const totalWeight = validCollectTreatments.reduce((acc, item) => acc + item.value, 0);
      mainCardsInfo[0].impact = formatNumber(Math.round(totalWeight)) + " kg";
      const totalPlastic = validCollectTreatments.find((item) => item.title === "Plásticos") || { value: 0 };
      mainCardsInfo[3].impact = formatNumber(Math.round(totalPlastic.value) * 0.3) + " kg";

      setTotalWeight(parseFloat(totalWeight.toFixed(2)));
      setPieLabels(validCollectTreatments.map((item) => item.title));
      setPieValues(validCollectTreatments.map((item) => item.value));
      setImpactCardsInfo(mainCardsInfo);
    } catch (error) {
      console.error("Erro ao buscar dados de impacto positivo:", error);
      setNoData(true);
      setImpactCardsInfo(impactCards); // Resetar os cards
      setTotalWeight(0);
      setPieLabels([]);
      setPieValues([]);
      // Resetar outras métricas se necessário
      setTotalCollectedByMonth([]);
      setTotalPlasticByMonth([]);
      setTotalGlassByMonth([]);
      setTotalMetalByMonth([]);
      setTotalPaperByMonth([]);
      setTotalOrganicByMonth([]);
      setPlaneKmEquivalent(0);
      setCarKmEquivalent(0);
      setAirConditionatorEquivalent(0);
      setShowerHoursEquivalent(0);
    } finally {
      setIsLoading(false);
    }
  }

  async function getPositiveImpactByMonth(year: number) {
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const initDate = `${year}-01-01`;
    const endDate = `${year}-12-31`;

    const collectedByMonth = Array(12).fill(0);
    const plasticByMonth = Array(12).fill(0);
    const glassByMonth = Array(12).fill(0);
    const metalByMonth = Array(12).fill(0);
    const paperByMonth = Array(12).fill(0);
    const organicByMonth = Array(12).fill(0);

    const fetchDataForMonth = async (month: string) => {
      const data = await CollectResiduesService.getByMonth(user_id, `${year}-${month}-01`, `${year}-${month}-31`);
      return Array.isArray(data) ? data : [];
    };

    try {
      const collectResiduesInfoMonthly = await Promise.all(months.map(fetchDataForMonth));

      collectResiduesInfoMonthly.forEach((monthlyData, index) => {
        collectedByMonth[index] = monthlyData.reduce((acc, item) => acc + (item.value || 0), 0);

        monthlyData.forEach((residue) => {
          switch (residue.title.trim().split(' ')[0]) {
            case 'Plásticos':
              plasticByMonth[index] += residue.value || 0;
              break;
            case 'Vidro':
              glassByMonth[index] += residue.value || 0;
              break;
            case 'Metais':
              metalByMonth[index] += residue.value || 0;
              break;
            case 'Papel':
              paperByMonth[index] += residue.value || 0;
              break;
            case 'Orgânicos':
              organicByMonth[index] += residue.value || 0;
              break;
          }
        });
      });

      setTotalCollectedByMonth(collectedByMonth);
      setTotalPlasticByMonth(plasticByMonth);
      setTotalGlassByMonth(glassByMonth);
      setTotalMetalByMonth(metalByMonth);
      setTotalPaperByMonth(paperByMonth);
      setTotalOrganicByMonth(organicByMonth);
    } catch (error) {
      console.error("Erro ao buscar dados de impacto positivo por mês:", error);
      // Opcional: definir estados para refletir erro ou ausência de dados
      setTotalCollectedByMonth([]);
      setTotalPlasticByMonth([]);
      setTotalGlassByMonth([]);
      setTotalMetalByMonth([]);
      setTotalPaperByMonth([]);
      setTotalOrganicByMonth([]);
    }
  }

  useEffect(() => {
    // Gerar a lista de anos apenas uma vez
    const currentYear = new Date().getFullYear();
    const generatedYears = Array.from({ length: 10 }, (_, i) => currentYear - i);
    setYears(generatedYears);

    // Carregar os dados iniciais
    checkLoginStatus();
    getPositiveImpact(selectedYear);
    getPositiveImpactByMonth(selectedYear);
  }, []); // Executar apenas uma vez no montante inicial

  useEffect(() => {
    // Recarregar os dados sempre que o ano selecionado mudar
    checkLoginStatus();
    getPositiveImpact(selectedYear);
    getPositiveImpactByMonth(selectedYear);
  }, [selectedYear]);

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const year = Number(e.target.value);
    setSelectedYear(year);
  };

  return (
    <PageContainer>
      {/* Contêiner responsivo para visualização na web */}
      <DisplayContainer ref={reportRef}>
        <HeaderContainer>
          <HeaderImg src={userImg} alt="User" />
          <HeaderMiddleContainer>
            <HeaderText>{mensagemTela}</HeaderText>
            <YearSelector value={selectedYear} onChange={handleYearChange}>
              {years.map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </YearSelector>
            {/* Botão de exportação removido daqui */}
          </HeaderMiddleContainer>
          <HeaderImg src={userImg} alt="User" />
        </HeaderContainer>

        {isLoading ? (
          <LoadingSpinner>Carregando...</LoadingSpinner>
        ) : noData ? (
          <NoDataContainer>
            <NoDataIcon />
            <NoDataText>
              Ainda não existem registros de pesagem para o ano <strong>{selectedYear}</strong>. 
              Por favor, verifique novamente mais tarde ou entre em contato com o suporte se acreditar que há um erro.
            </NoDataText>
          </NoDataContainer>
        ) : (
          <>
            <CardsContainer container spacing={2}>
              {impactCardsInfo.map((card) => (
                <Grid item xs={6} sm={3} key={card.id}>
                  <ImpactCard impact={card.impact} text={card.subText} imageSrc={card.imageSrc} />
                </Grid>
              ))}
            </CardsContainer>

            <GraphsContainer>
              <BarGraphContainer>
                <ImpactBar
                  data_1={totalCollectedByMonth}
                  data_2={totalPlasticByMonth}
                  data_3={totalGlassByMonth}
                  data_4={totalMetalByMonth}
                  data_5={totalPaperByMonth}
                  data_6={totalOrganicByMonth}
                  selectedYear={selectedYear} // Passando o ano selecionado
                />
              </BarGraphContainer>
              <PieGraphContainer>
                <ImpactPie labels={pieLabels} values={pieValues} />
              </PieGraphContainer>
            </GraphsContainer>
            <HeaderText>As emissões evitadas equivalem a:</HeaderText>
            <FooterContainer>
              <FooterImpactCardsContainer>
                <FooterImpactCard>
                  <FooterImpactImage src={Carro} alt="Emissões Evitadas" />
                  <FooterImpactTitle>Quilômetros Rodados em Carro de Médio Porte</FooterImpactTitle>
                  <FooterImpactValue>{formatNumber(carKmEquivalent)} km</FooterImpactValue>
                </FooterImpactCard>
                <FooterImpactCard>
                  <FooterImpactImage src={Aviao} alt="Árvores Salvas" />
                  <FooterImpactTitle>Quilômetros de Voo Comercial</FooterImpactTitle>
                  <FooterImpactValue>{formatNumber(Math.round(planeKmEquivalent))} km</FooterImpactValue>
                </FooterImpactCard>
                <FooterImpactCard>
                  <FooterImpactImage src={Chuveiro} alt="Plásticos no Oceano" />
                  <FooterImpactTitle>Horas de Chuveiro Elétrico Ligado</FooterImpactTitle>
                  <FooterImpactValue>{formatNumber(showerHoursEquivalent)} horas</FooterImpactValue>
                </FooterImpactCard>
                <FooterImpactCard>
                  <FooterImpactImage src={ArCondicionado} alt="Total Reciclado" />
                  <FooterImpactTitle>Horas de Ar Condicionado Ligado</FooterImpactTitle>
                  <FooterImpactValue>{formatNumber(airConditionatorEquivalent)} Horas</FooterImpactValue>
                </FooterImpactCard>
              </FooterImpactCardsContainer>

              <FooterInfoContainer>
                <FooterSiriContainer>
                  <FooterLogo src={SiriLogoSvg} alt="Siri Logo" />
                  <FooterText>Vem reciclar também!!!</FooterText>
                </FooterSiriContainer>
                <FooterSiriContainer>
                  <FooterText>Escaneie o código para saber mais</FooterText>
                  <QrCodeContainer>
                    <img src={QrCodeImage} width={100} height={100} alt="QR Code" />
                  </QrCodeContainer>
                </FooterSiriContainer>
              </FooterInfoContainer>
            </FooterContainer>
          </>
        )}
      </DisplayContainer>

      {/* Contêiner oculto para exportação */}
      <ExportContainer ref={exportRef}>
        <HeaderContainer>
          <HeaderImg src={userImg} alt="User" />
          <HeaderMiddleContainer>
            <HeaderText>{mensagemTela}</HeaderText>
            <YearSelector value={selectedYear} disabled>
              {years.map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </YearSelector>
          </HeaderMiddleContainer>
          <HeaderImg src={userImg} alt="User" />
        </HeaderContainer>

        {isLoading ? (
          <LoadingSpinner>Carregando...</LoadingSpinner>
        ) : noData ? (
          <NoDataContainer>
            <NoDataIcon />
            <NoDataText>
              Ainda não existem registros de pesagem para o ano <strong>{selectedYear}</strong>. 
              Por favor, verifique novamente mais tarde ou entre em contato com o suporte se acreditar que há um erro.
            </NoDataText>
          </NoDataContainer>
        ) : (
          <>
            <CardsContainer container spacing={2}>
              {impactCardsInfo.map((card) => (
                <Grid item xs={6} sm={3} key={card.id}>
                  <ImpactCard impact={card.impact} text={card.subText} imageSrc={card.imageSrc} />
                </Grid>
              ))}
            </CardsContainer>

            <GraphsContainer>
              <BarGraphContainer>
                <ImpactBar
                  data_1={totalCollectedByMonth}
                  data_2={totalPlasticByMonth}
                  data_3={totalGlassByMonth}
                  data_4={totalMetalByMonth}
                  data_5={totalPaperByMonth}
                  data_6={totalOrganicByMonth}
                  selectedYear={selectedYear} // Passando o ano selecionado
                />
              </BarGraphContainer>
              <PieGraphContainer>
                <ImpactPie labels={pieLabels} values={pieValues} />
              </PieGraphContainer>
            </GraphsContainer>
            <HeaderText>As emissões evitadas equivalem a:</HeaderText>
            <FooterContainer>
              <FooterImpactCardsContainer>
                <FooterImpactCard>
                  <FooterImpactImage src={Carro} alt="Emissões Evitadas" />
                  <FooterImpactTitle>Quilômetros Rodados em Carro de Médio Porte</FooterImpactTitle>
                  <FooterImpactValue>{formatNumber(carKmEquivalent)} km</FooterImpactValue>
                </FooterImpactCard>
                <FooterImpactCard>
                  <FooterImpactImage src={Aviao} alt="Árvores Salvas" />
                  <FooterImpactTitle>Quilômetros de Voo Comercial</FooterImpactTitle>
                  <FooterImpactValue>{formatNumber(Math.round(planeKmEquivalent))} km</FooterImpactValue>
                </FooterImpactCard>
                <FooterImpactCard>
                  <FooterImpactImage src={Chuveiro} alt="Plásticos no Oceano" />
                  <FooterImpactTitle>Horas de Chuveiro Elétrico Ligado</FooterImpactTitle>
                  <FooterImpactValue>{formatNumber(showerHoursEquivalent)} horas</FooterImpactValue>
                </FooterImpactCard>
                <FooterImpactCard>
                  <FooterImpactImage src={ArCondicionado} alt="Total Reciclado" />
                  <FooterImpactTitle>Horas de Ar Condicionado Ligado</FooterImpactTitle>
                  <FooterImpactValue>{formatNumber(airConditionatorEquivalent)} Horas</FooterImpactValue>
                </FooterImpactCard>
              </FooterImpactCardsContainer>

              <FooterInfoContainer>
                <FooterSiriContainer>
                  <FooterLogo src={SiriLogoSvg} alt="Siri Logo" />
                  <FooterText>Vem reciclar também!!!</FooterText>
                </FooterSiriContainer>
                <FooterSiriContainer>
                  <FooterText>Escaneie o código para saber mais</FooterText>
                  <QrCodeContainer>
                    <img src={QrCodeImage} width={100} height={100} alt="QR Code" />
                  </QrCodeContainer>
                </FooterSiriContainer>
              </FooterInfoContainer>
            </FooterContainer>
          </>
        )}
      </ExportContainer>

      {/* Botão de Exportação Fora do Conteúdo a Ser Exportado */}
      <ExportButton onClick={exportToPDF}>Exportar para PDF</ExportButton>
    </PageContainer>
  );
}