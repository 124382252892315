import React from 'react';
import styled from 'styled-components';

const CardsContainer = styled.div<{ isTV: boolean }>`
  display: flex;
  position: relative; /* Permite posicionar elementos absolutos dentro */
  width: ${({ isTV }) => (isTV ? '250px' : '90%')};
  height: ${({ isTV }) => (isTV ? '12rem' : '8rem')};
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: var(--shadow-2);
  margin: 10px;

  @media (min-width: 1921px) {
    width: ${({ isTV }) => (isTV ? '300px' : '100%')};
    height: ${({ isTV }) => (isTV ? '14rem' : '10rem')};
  }
`;

const ImgContainer = styled.div<{ isTV: boolean }>`
  /* Mantemos layout normal para imagem */
  width: ${({ isTV }) => (isTV ? '50%' : '45%')};
  padding: ${({ isTV }) => (isTV ? '15px 10px 15px 15px' : '10px 0 10px 10px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div<{ isTV: boolean }>`
  /* Pode deixar flex normal ou outro layout */
  width: ${({ isTV }) => (isTV ? '50%' : '55%')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding-top: ${({ isTV }) => (isTV ? '15px' : '10px')};
`;

const SubText = styled.h5<{ isTV: boolean }>`
  text-transform: uppercase;
  margin: 0;

   @media (max-width: 1500px) {
    font-size: 0.75rem;
  }
  
  @media (min-width: 1921px) {
    font-size: 1.5rem;
  }
`;

const MainText = styled.h1<{ isTV: boolean }>`
  /* Aqui definimos a posição absoluta e a distância do bottom */
  position: absolute;
  bottom: 10px;      /* Fixa 10px do bottom do card */
  font-size: 1.5rem;
  line-height: 2.6rem;
  margin: 0;

  @media (max-width: 1200px) {
    font-size: 1rem;
  }
  
   @media (max-width: 1500px) {
    font-size: 1.2rem;
  }

  @media (min-width: 1921px) {
    font-size: 2rem;
  }
`;

type ImpactCardProps = {
  impact: string;
  text: string;
  imageSrc: string;
  isTV?: boolean;
};

const ImpactCard: React.FC<ImpactCardProps> = ({ impact, text, imageSrc, isTV = false }) => {
  return (
    <CardsContainer isTV={isTV}>
      <ImgContainer isTV={isTV}>
        <img src={imageSrc} alt={text} width={isTV ? 120 : 100} height={isTV ? 120 : 100} />
      </ImgContainer>
      <TextContainer isTV={isTV}>
        <SubText isTV={isTV}>{text}</SubText>
        <MainText isTV={isTV}>{impact}</MainText>
      </TextContainer>
    </CardsContainer>
  );
};

export default ImpactCard;